<template>
  <div>
    <el-card>
      <el-tag
        size="medium"
        style="
          margin-bottom: 50px;
          line-height: 30px;

          height: 30px;
          text-align: center;
          font-size: 14px;
        "
        >管理员基本信息</el-tag
      >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="用户名">
          <el-input v-model="form.username" />
        </el-form-item>

        <el-form-item label="真实姓名">
          <el-input v-model="form.realName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password" show-password autocomplete="off" />
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="form.phone" autocomplete="off" />
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="form.groupId" placeholder="请选择">
            <el-option
              v-for="(item, index) in role"
              :key="index"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="配图">
          <el-upload
            class="avatar-uploader"
            :action='this.$store.getters.imgUrl'
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
          >
            <img v-if="form.picture" :src="form.picture" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
        <!-- <el-form-item label="内容">
          <el-input v-model="form.content" type="textarea" :rows='7'></el-input>
        </el-form-item> -->
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="启用" :value="1" />
            <el-option label="禁用" :value="2" />
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-button
      v-if="!uid"
      type="primary"
      style="margin-top: 50px"
      @click="editform"
      >确定保存</el-button
    >
    <el-button
      v-if="uid"
      type="primary"
      style="margin-top: 50px"
      @click="editform_1"
      >确定修改</el-button
    >
    <el-button
      type="primary"
      style="margin-top: 50px; width: 98px"
      @click="rest"
      >取&nbsp;&nbsp;&nbsp;&nbsp;消</el-button
    >
  </div>
</template>

<script>
// import $ from 'jquery'
// import {WebUploader} from '../../assets/webuploader'

export default {
  data() {
    return {
      form: {
        username: '',
        realName: '',
        password: '',
        status: 1,
        phone: ''
      },
      btn: true,
      imageUrl: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      role: [],
      baseurl: process.env.VUE_APP_STATIC,
      uid: this.$route.query.id
    }
  },
  created() {
    this.getform()
    this.bus.$on('new', () => {
      this.form = {
        username: '',
        realName: '',
        password: '',
        status: 1,
        phone: ''
      }
    })
  },
  mounted: function() {},
  methods: {
    rest() {
      // this.form={
      //   logo: '',
      //   keep_record: '',
      //   ipc: '',
      //   copyright: '',
      // }
      this.$refs.form.resetFields()
      // this.imgsrc = ''
      this.form = {}
    },
    getform() {
      if (this.uid) {
        this.$http
          .get('/admin/AuthMember/getInfo?uid=' + this.uid)
          .then(({ data: res }) => {
            console.log(res)
            if (res.errorCode == 200) {
              this.form = res.data
            }
          })
      }

      this.$http.get('/admin/AuthGroup/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.role = res.data.list
        }
      })
    },

    editform() {
      if (this.btn) {
        this.$http
          .post('/admin/AuthMember/add', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.$router.push('/system_admin')
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    editform_1() {
      this.form.uid = this.uid
      if (this.btn) {
        this.$http
          .post('/admin/AuthMember/edit', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.$router.push('/system_admin')
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file)
      this.sysConfig.logo = this.baseurl + res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.img {
  // width: 500px;
  height: 200px;
  width: auto;
  // height: 200px;
  border: 0;
  outline: 0;
}
img[src=''] {
  opacity: 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  // width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  // width: 500px;
  height: 178px;
  display: block;
}
</style>
